html {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bgstyle {

  background-size: 1200% 1200%;
  animation: BackgroundAnimation 500s ease infinite;
  height: 100%;
}

.bg-gradient {
  background: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245, 0));
}

.navbar-brand {
  color: white !important;
  transition: color 0.2s;
}
.navbar-transparent {
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.2s;
}
.navbar-white {
  background-color: rgba(121, 121, 121, 0.4);
  transition: background-color 0.2s;
}
.nav-link {
  color: white !important;
  transition: color 0.2s;
}
.nav-item {
  transition: color, 0.2s;
}
.toggler {
  border: none;
  padding: 4px;
  border-radius: 4px;
  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.socialicons {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;
  &:hover {
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.8s;
    margin: 4px;
  }
}

.title {
  height: 100%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  margin-top: 0px;
}

.typist {
  min-height: 6rem;
  @media (min-width: 360px) {
    min-height: 4rem;
  }
  @media (min-width: 768px) {
    min-height: 2.5rem;
  }
}

.window {
  background-color: #fafafa;
  border: 1px solid #161925;
  box-shadow: 0.3rem 0.3rem;
  color: #000;
  position: absolute;
  z-index: 2;
}

.top {
  border-bottom: 1px solid #161925;
  cursor: default;
  overflow: auto;
  text-align: center;
}

.close {
  border-left: 1px solid #161925;
  float: right;
  padding-left: 5px;
  padding-right: 5px;
}

.close:hover,
#close:hover {
  background-color: #7d8ca3;
}

#close {
  cursor: pointer;
  right: 5%;
  top: 50px;
  padding: 0.2em;
  z-index: 5;
}

.text {
  margin: 1em;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.window-img {
  object-fit: cover;
  width: 450px;
  height: auto;
}

.window-open {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s, transform 0.5s;
}

.window-close {
  opacity: 0;
  transform: scale(0);
  transition: opacity 1s, transform 0.5s;
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    box-shadow: 0 2rem 3rem rgba($black, 0.175) !important;
    transform: translateY(-2px);
    transition: transform 0.3s, box-shadow 0.3s;
  }
}

.skills-btn {
  display: block;
  cursor: pointer;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: lowercase;
  transition: transform 0.2s ease-in-out;
}

.skills-btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.skills-btn--plain:hover {
  transform: translateY(-4px);
}

.skills__list {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
}

.progress {
  transform-origin: left;
  & > * {
    transform: scaleX(0);
  }
}
.progress-bar-animation {
  @extend .progress;
  & > * {
    animation: progress 1.5s ease-in-out forwards;
  }
}
